import axios from '../../services/api';
import React, { useEffect } from 'react';
import { Button, message, Switch } from 'antd';
import { useParams } from 'react-router-dom';
import { auth } from '../../firebase.config';

const formItems = [
    {
        label: 'Vechicle Name',
        name: 'name',
    },
    {
        label: 'Vechicle Type',
        name: 'type',
    },
    {
        label: 'Description',
        name: 'description',
        type: 'textarea',
    },
    {
        label: 'RC Number',
        name: 'rcNumber',
    },
    {
        label: 'Model',
        name: 'model',
    },
    {
        label: 'Manufacturer',
        name: 'manufacturer',
    },
    {
        label: 'Color',
        name: 'color',
    },
    {
        label: 'Speed',
        name: 'speed',
    },
    {
        label: 'Gears',
        name: 'gears',
    },
    {
        label: 'Fuel',
        name: 'fuel',
    },
    {
        label: 'Fuel Capacity',
        name: 'fuelCapacity',
    },
    {
        label: 'Price',
        name: 'price',
    },
    {
        label: 'Minimum Booking Duration',
        name: 'minDuration',
    },
    {
        label: 'Mileage',
        name: 'mileage',
    },
    {
        label: 'CC',
        name: 'cc',
    },
    {
        label: 'Location',
        name: 'location',
    },
    {
        label: 'Featured Image',
        name: 'image',
    },
];

const AddBike = () => {
    const { id } = useParams();
    const [formData, setFormData] = React.useState({
        ...formItems.reduce((acc, item) => {
            acc[item.name] = '';
            return acc;
        }, {}),
    });
    useEffect(() => {
        // listen path change
        console.log(id);
    });
    const [features, setFeatures] = React.useState(['', '', '', '']);
    const [images, setImages] = React.useState(['', '', '']);
    const [includes, setIncludes] = React.useState(['', '', '']);
    const [loading, setLoading] = React.useState(false);
    // const { user } = React.useContext(AuthContext);
    const addFeature = () => {
        setFeatures([...features, '']);
    };

    useEffect(() => {
        // empty form data
        setFormData({
            ...formItems.reduce((acc, item) => {
                acc[item.name] = '';
                return acc;
            }, {}),
        });
        setFeatures(['', '', '', '']);
        setImages(['', '', '']);
        setIncludes(['', '', '']);

        if (id) {
            console.log(id);

            // get token firebase
            // auth.currentUser.getIdToken().then((token) => {
            axios
                .get(`/vehicle/${id}`)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    var vehicleData = data.data;
                    var finalData = formItems
                        .map((item) => {
                            return { [item.name]: vehicleData[item.name] };
                        })
                        .reduce((acc, item) => {
                            acc = { ...acc, ...item };
                            return acc;
                        }, {});
                    finalData.status = vehicleData.status;
                    setFormData(finalData);
                    setFeatures([...vehicleData.features]);
                    setImages([...vehicleData.images]);
                    setIncludes([...vehicleData.included]);
                    console.log(vehicleData, vehicleData.features, vehicleData.images);
                })
                .catch((err) => {
                    console.log(err);
                    // });
                });
        }
    }, [id]);
    useEffect(() => {
        // log features
        console.log(features);
    }, [features]);
    // remove feature when greater than 4
    const removeFeature = (index) => {
        if (features.length > 4) {
            setFeatures(features.filter((_, i) => i !== index));
        }
    };

    const addImage = () => {
        setImages([...images, '']);
    };
    // remove image when greater than 3
    const removeImage = (index) => {
        if (images.length > 3) {
            setImages(images.filter((_, i) => i !== index));
        }
    };
    // remove include when greater than 3
    const removeInclude = (index) => {
        if (includes.length > 3) {
            setIncludes(includes.filter((_, i) => i !== index));
        }
    };

    // submit form
    const submitForm = (e) => {
        e.preventDefault();
        setLoading(true);
        console.log('submitting form');
        // form data
        // const formData = new FormData(e.target);
        console.log(formData);

        // form data to json
        const data = formData;
        if (id) {
            var finalData = { ...data, features, images, included: includes, addons: [] };
            console.log(finalData);
            axios
                .put(`/vehicle/${id}`, finalData)
                .then((res) => {
                    message.success('Bike updated successfully');
                })
                .catch((err) => {
                    message.error('Error updating bike');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            var finalData = { ...data, features, images, included: includes, addons: [], status: 'inactive' };
            console.log(finalData);
            axios
                .post('/vehicle', finalData)
                .then((res) => {
                    message.success('Bike added successfully');
                    // reset form
                    e.target.reset();
                    setFeatures(['', '', '', '']);
                    setImages(['', '', '', '']);
                    setIncludes(['', '', '']);
                })
                .catch((err) => {
                    message.error('Error adding bike');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <div>
            <div className="flex flex-wrap -mx-3">
                <div className="w-full max-w-full px-3 shrink-0 md:w-12/12 md:flex-0">
                    <div className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                        <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                            <div className="flex items-center">
                                <p className="mb-0 dark:text-white/80">{!id ? 'Add' : 'Edit'} Bike</p>
                                {/* <button
                                    type="button"
                                    className="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                >
                                    Settings
                                </button> */}
                            </div>
                        </div>
                        <div className="flex-auto p-6">
                            <form onSubmit={submitForm}>
                                <p className="leading-normal uppercase dark:text-white dark:opacity-60 text-sm">Vehicle Information</p>
                                <div className="flex flex-wrap -mx-3">
                                    {formItems.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={'w-full max-w-full px-3 shrink-0 md:flex-0' + (item.type == 'textarea' ? ' md:w-12/12' : '  md:w-6/12')}
                                            >
                                                <div className="mb-4">
                                                    <label htmlFor={item.label} className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                        {item.label}
                                                    </label>
                                                    {item.type === 'textarea' ? (
                                                        <textarea
                                                            name={item.name}
                                                            value={formData[item.name]}
                                                            onChange={(e) => setFormData({ ...formData, [item.name]: e.target.value })}
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            placeholder={item.label}
                                                            required
                                                        />
                                                    ) : (
                                                        <input
                                                            type="text"
                                                            name={item.name}
                                                            placeholder={item.label}
                                                            value={formData[item.name]}
                                                            onChange={(e) => setFormData({ ...formData, [item.name]: e.target.value })}
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            required
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {
                                        // status switch
                                        <div className="w-full max-w-full px-3 shrink-0 md:flex-0 md:w-6/12">
                                            <div className="mb-4 flex justify-between items-center">
                                                <label htmlFor="status" className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                    Status
                                                </label>
                                                <Switch
                                                    checkedChildren="active"
                                                    unCheckedChildren="inactive"
                                                    checked={formData.status == 'active'}
                                                    defaultChecked={formData.status == 'active'}
                                                    onChange={(checked) => setFormData({ ...formData, status: checked ? 'active' : 'inactive' })}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <hr className="h-px mx-0 my-4 bg-transparent border-0 opacity-25 bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent " />
                                {
                                    // Features
                                    <>
                                        <p className="leading-normal uppercase dark:text-white dark:opacity-60 text-sm">Features</p>
                                        <div className="flex flex-wrap -mx-3">
                                            {features.map((feature, index) => {
                                                return (
                                                    <div key={index} className="w-full max-w-full px-3 shrink-0 md:flex-0 md:w-6/12">
                                                        <div className="mb-4 flex flex-row items-end">
                                                            <div className="w-full">
                                                                <label
                                                                    htmlFor="feature"
                                                                    className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                                >
                                                                    Feature {index + 1}
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="feature"
                                                                    placeholder="Feature"
                                                                    value={feature}
                                                                    onChange={(e) => {
                                                                        const newFeatures = [...features];
                                                                        newFeatures[index] = e.target.value;
                                                                        setFeatures(newFeatures);
                                                                    }}
                                                                    className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="px-4 py-2 mx-2 h-8 w-8 flex justify-center items-center rounded-full border border-white border-solid font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-red-500 shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                                onClick={() => removeFeature(index)}
                                                            >
                                                                <i className="fa fa-minus text-xs"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="flex flex-wrap -mx-3">
                                            <div className="w-full max-w-full px-3 shrink-0 md:flex-0 md:w-6/12">
                                                <div className="mb-4">
                                                    <button
                                                        type="button"
                                                        className="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                        onClick={() => setFeatures([...features, ''])}
                                                    >
                                                        Add Feature
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <hr className="h-px mx-0 my-4 bg-transparent border-0 opacity-25 bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent " />
                                {
                                    // add images section
                                    <>
                                        <p className="leading-normal uppercase dark:text-white dark:opacity-60 text-sm">Images</p>
                                        <div className="flex flex-wrap -mx-3">
                                            {images.map((image, index) => {
                                                return (
                                                    <div key={index} className="w-full max-w-full px-3 shrink-0 md:flex-0 md:w-6/12">
                                                        <div className="mb-4 flex flex-row items-end">
                                                            <div className="w-full">
                                                                <label
                                                                    htmlFor="image"
                                                                    className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                                >
                                                                    Image {index + 1}
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="image"
                                                                    placeholder="Image"
                                                                    value={image}
                                                                    onChange={(e) => {
                                                                        const newImages = [...images];
                                                                        newImages[index] = e.target.value;
                                                                        setImages(newImages);
                                                                    }}
                                                                    className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="px-4 py-2 mx-2 h-8 w-8 flex justify-center items-center rounded-full border border-white border-solid font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-red-500 shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                                onClick={() => removeImage(index)}
                                                            >
                                                                <i className="fa fa-minus text-xs"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="flex flex-wrap -mx-3">
                                            <div className="w-full max-w-full px-3 shrink-0 md:flex-0 md:w-6/12">
                                                <div className="mb-4">
                                                    <button
                                                        type="button"
                                                        className="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                        onClick={() => setImages([...images, ''])}
                                                    >
                                                        Add Image
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    // add includes section
                                    <>
                                        <p className="leading-normal uppercase dark:text-white dark:opacity-60 text-sm">Includes</p>
                                        <div className="flex flex-wrap -mx-3">
                                            {includes.map((include, index) => {
                                                return (
                                                    <div key={index} className="w-full max-w-full px-3 shrink-0 md:flex-0 md:w-6/12">
                                                        <div className="mb-4 flex flex-row items-end">
                                                            <div className="w-full">
                                                                <label
                                                                    htmlFor="include"
                                                                    className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                                >
                                                                    Include {index + 1}
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="include"
                                                                    placeholder="Include"
                                                                    value={include}
                                                                    onChange={(e) => {
                                                                        const newIncludes = [...includes];
                                                                        newIncludes[index] = e.target.value;
                                                                        setIncludes(newIncludes);
                                                                    }}
                                                                    className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="px-4 py-2 mx-2 h-8 w-8 flex justify-center items-center rounded-full border border-white border-solid font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-red-500 shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                                onClick={() => removeInclude(index)}
                                                            >
                                                                <i className="fa fa-minus text-xs"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="flex flex-wrap -mx-3">
                                            <div className="w-full max-w-full px-3 shrink-0 md:flex-0 md:w-6/12">
                                                <div className="mb-4">
                                                    <button
                                                        type="button"
                                                        className="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                        onClick={() => setIncludes([...includes, ''])}
                                                    >
                                                        Add Include
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    // Submit
                                    <>
                                        <div className="flex flex-wrap justify-center">
                                            <div className="mb-4">
                                                <Button
                                                    htmlType="submit"
                                                    className="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddBike;
