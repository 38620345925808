import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import React from "react";
import {auth} from "../firebase.config";


export const logout = async () => {
    await signOut(auth);
}

// create auth context
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            console.log(user);

            setUser(user);
            auth.currentUser?.getIdToken(true).then((idToken) => {
                console.log(idToken);
            }).catch((error) => {
                // Handle error
            })
            setLoading(false);
        });
        // get token
        return unsubscribe;
    }, []);

    const value = { user };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
