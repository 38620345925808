import { message } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
const provider = new GoogleAuthProvider();

const Login = () => {
    const history = useNavigate();
    return (
        <div className="py-1 m-0">
            <nav className="absolute top-0 z-30 flex flex-wrap items-center justify-between w-full px-4 py-4 mb-4 shadow-none lg:flex-nowrap lg:justify-start">
                <div className="container flex items-center justify-between py-0 flex-wrap-inherit">
                    <Link to={'/'} className="py-1.75 ml-4 mr-4 font-bold text-white text-sm whitespace-nowrap lg:ml-0">
                        {' '}
                        StartRides Admin{' '}
                    </Link>
                    <button
                        navbar-trigger
                        className="px-3 py-1 ml-2 leading-none transition-all ease-in-out bg-transparent border border-transparent border-solid rounded-lg shadow-none cursor-pointer text-lg lg:hidden"
                        type="button"
                        aria-controls="navigation"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="inline-block mt-2 align-middle bg-center bg-no-repeat bg-cover w-6 h-6 bg-none">
                            <span bar1 className="w-5.5 rounded-xs duration-350 relative my-0 mx-auto block h-px bg-white transition-all"></span>
                            <span bar2 className="w-5.5 rounded-xs mt-1.75 duration-350 relative my-0 mx-auto block h-px bg-white transition-all"></span>
                            <span bar3 className="w-5.5 rounded-xs mt-1.75 duration-350 relative my-0 mx-auto block h-px bg-white transition-all"></span>
                        </span>
                    </button>
                    <div
                        navbar-menu
                        className="items-center flex-grow transition-all ease duration-350 lg-max:bg-white lg-max:max-h-0 lg-max:overflow-hidden basis-full rounded-2xl lg:flex lg:basis-auto"
                    ></div>
                </div>
            </nav>

            <main className="mt-0 transition-all duration-200 ease-in-out">
                <section className="min-h-screen">
                    <div className="bg-top relative flex items-start pt-12 pb-56 m-4 overflow-hidden bg-cover min-h-50-screen rounded-xl bg-[url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg')]">
                        <span className="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-zinc-800 to-zinc-700 opacity-60"></span>
                        <div className="container z-10">
                            <div className="flex flex-wrap justify-center -mx-3">
                                <div className="w-full max-w-full px-3 mx-auto mt-0 text-center lg:flex-0 shrink-0 lg:w-5/12">
                                    <h1 className="mt-12 mb-2 text-white">Welcome!</h1>
                                    {/* <p className="text-white">Use these awesome forms to login or create new account in your project for free.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="flex flex-wrap -mx-3 -mt-48 md:-mt-56 lg:-mt-48">
                            <div className="w-full max-w-full px-3 mx-auto mt-0 md:flex-0 shrink-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
                                <div className="relative z-0 flex flex-col min-w-0 break-words bg-white border-0 shadow-xl rounded-2xl bg-clip-border">
                                    <div className="p-6 mb-0 text-center bg-white border-b-0 rounded-t-2xl">
                                        <h5>Login with</h5>
                                    </div>
                                    <div className="flex flex-wrap px-3 -mx-3 sm:px-6 xl:px-12 justify-center items-center mb-4">
                                        <div className="w-3/12 max-w-full px-1 flex-0 ">
                                            <button
                                                className="inline-block w-full px-5 py-2.5 mb-4 font-bold text-center text-gray-200 uppercase align-middle transition-all bg-transparent border border-gray-200 border-solid rounded-lg shadow-none cursor-pointer hover:-translate-y-px leading-pro text-xs ease-in tracking-tight-rem bg-150 bg-x-25 hover:bg-transparent hover:opacity-75"
                                                onClick={() => {
                                                    const auth = getAuth();

                                                    signInWithPopup(auth, provider)
                                                        .then((result) => {
                                                            // This gives you a Google Access Token. You can use it to access the Google API.
                                                            const credential = provider.credentialFromResult(result);
                                                            const token = credential?.accessToken;
                                                            message.success('Logged in with Google Successfully');
                                                            const user = result.user;
                                                            history('/');
                                                            // get token
                                                            // The signed-in user info.

                                                            // ...
                                                        })
                                                        .catch((error) => {
                                                            // Handle Errors here.
                                                            const errorCode = error.code;
                                                            const errorMessage = error.message;
                                                            // The email of the user's account used.
                                                            const email = error.customData.email;
                                                            // The AuthCredential type that was used.
                                                            const credential = provider.credentialFromError(error);
                                                            message.error(errorMessage);
                                                            // ...
                                                        });
                                                }}
                                            >
                                                <svg width="24px" height="32px" viewBox="0 0 64 64" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <g transform="translate(3.000000, 2.000000)" fill-rule="nonzero">
                                                            <path
                                                                d="M57.8123233,30.1515267 C57.8123233,27.7263183 57.6155321,25.9565533 57.1896408,24.1212666 L29.4960833,24.1212666 L29.4960833,35.0674653 L45.7515771,35.0674653 C45.4239683,37.7877475 43.6542033,41.8844383 39.7213169,44.6372555 L39.6661883,45.0037254 L48.4223791,51.7870338 L49.0290201,51.8475849 C54.6004021,46.7020943 57.8123233,39.1313952 57.8123233,30.1515267"
                                                                fill="#4285F4"
                                                            ></path>
                                                            <path
                                                                d="M29.4960833,58.9921667 C37.4599129,58.9921667 44.1456164,56.3701671 49.0290201,51.8475849 L39.7213169,44.6372555 C37.2305867,46.3742596 33.887622,47.5868638 29.4960833,47.5868638 C21.6960582,47.5868638 15.0758763,42.4415991 12.7159637,35.3297782 L12.3700541,35.3591501 L3.26524241,42.4054492 L3.14617358,42.736447 C7.9965904,52.3717589 17.959737,58.9921667 29.4960833,58.9921667"
                                                                fill="#34A853"
                                                            ></path>
                                                            <path
                                                                d="M12.7159637,35.3297782 C12.0932812,33.4944915 11.7329116,31.5279353 11.7329116,29.4960833 C11.7329116,27.4640054 12.0932812,25.4976752 12.6832029,23.6623884 L12.6667095,23.2715173 L3.44779955,16.1120237 L3.14617358,16.2554937 C1.14708246,20.2539019 0,24.7439491 0,29.4960833 C0,34.2482175 1.14708246,38.7380388 3.14617358,42.736447 L12.7159637,35.3297782"
                                                                fill="#FBBC05"
                                                            ></path>
                                                            <path
                                                                d="M29.4960833,11.4050769 C35.0347044,11.4050769 38.7707997,13.7975244 40.9011602,15.7968415 L49.2255853,7.66898166 C44.1130815,2.91684746 37.4599129,0 29.4960833,0 C17.959737,0 7.9965904,6.62018183 3.14617358,16.2554937 L12.6832029,23.6623884 C15.0758763,16.5505675 21.6960582,11.4050769 29.4960833,11.4050769"
                                                                fill="#EB4335"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                        {/* <div className="relative w-full max-w-full px-3 mt-2 text-center shrink-0">
                                            <p className="z-20 inline px-4 mb-2 font-semibold leading-normal bg-white text-sm text-slate-400">or</p>
                                        </div> */}
                                    </div>
                                    {/* <div className="flex-auto p-6">
                                        <form role="form text-left">
                                            <div className="mb-4">
                                                <input
                                                    type="text"
                                                    className="placeholder:text-gray-500 text-sm focus:shadow-primary-outline leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                                                    placeholder="Name"
                                                    aria-label="Name"
                                                    aria-describedby="email-addon"
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <input
                                                    type="email"
                                                    className="placeholder:text-gray-500 text-sm focus:shadow-primary-outline leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                                                    placeholder="Email"
                                                    aria-label="Email"
                                                    aria-describedby="email-addon"
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <input
                                                    type="password"
                                                    className="placeholder:text-gray-500 text-sm focus:shadow-primary-outline leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                                                    placeholder="Password"
                                                    aria-label="Password"
                                                    aria-describedby="password-addon"
                                                />
                                            </div>
                                            <div className="min-h-6 pl-7 mb-0.5 block">
                                                <input
                                                    className="w-4.8 h-4.8 ease -ml-7 rounded-1.4 checked:bg-gradient-to-tl checked:from-blue-500 checked:to-violet-500 after:text-xxs after:font-awesome after:duration-250 after:ease-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                                                    type="checkbox"
                                                    value=""
                                                    checked
                                                />
                                                <label className="mb-2 ml-1 font-normal cursor-pointer text-sm text-slate-700" for="flexCheckDefault">
                                                    {' '}
                                                    I agree the{' '}
                                                    <a href="javascript:;" className="font-bold text-slate-700">
                                                        Terms and Conditions
                                                    </a>{' '}
                                                </label>
                                            </div>
                                            <div className="text-center">
                                                <button
                                                    type="button"
                                                    className="inline-block w-full px-5 py-2.5 mt-6 mb-2 font-bold text-center text-white align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer active:opacity-85 hover:-translate-y-px hover:shadow-xs leading-normal text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25 bg-gradient-to-tl from-zinc-800 to-zinc-700 hover:border-slate-700 hover:bg-slate-700 hover:text-white"
                                                >
                                                    Sign up
                                                </button>
                                            </div>
                                            <p className="mt-4 mb-0 leading-normal text-sm">
                                                Already have an account?{' '}
                                                <a href="../pages/sign-in.html" className="font-bold text-slate-700">
                                                    Sign in
                                                </a>
                                            </p>
                                        </form>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <footer className="py-12">
                    <div className="container">
                        <div className="flex flex-wrap -mx-3">
                            <div className="flex-shrink-0 w-full max-w-full mx-auto mb-6 text-center lg:flex-0 lg:w-8/12">
                                <a href="javascript:;" target="_blank" className="mb-2 mr-4 text-slate-400 sm:mb-0 xl:mr-12">
                                    {' '}
                                    Company{' '}
                                </a>
                                <a href="javascript:;" target="_blank" className="mb-2 mr-4 text-slate-400 sm:mb-0 xl:mr-12">
                                    {' '}
                                    About Us{' '}
                                </a>
                                <a href="javascript:;" target="_blank" className="mb-2 mr-4 text-slate-400 sm:mb-0 xl:mr-12">
                                    {' '}
                                    Team{' '}
                                </a>
                                <a href="javascript:;" target="_blank" className="mb-2 mr-4 text-slate-400 sm:mb-0 xl:mr-12">
                                    {' '}
                                    Products{' '}
                                </a>
                                <a href="javascript:;" target="_blank" className="mb-2 mr-4 text-slate-400 sm:mb-0 xl:mr-12">
                                    {' '}
                                    Blog{' '}
                                </a>
                                <a href="javascript:;" target="_blank" className="mb-2 mr-4 text-slate-400 sm:mb-0 xl:mr-12">
                                    {' '}
                                    Pricing{' '}
                                </a>
                            </div>
                            <div className="flex-shrink-0 w-full max-w-full mx-auto mt-2 mb-6 text-center lg:flex-0 lg:w-8/12">
                                <a href="javascript:;" target="_blank" className="mr-6 text-slate-400">
                                    <span className="text-lg fab fa-dribbble"></span>
                                </a>
                                <a href="javascript:;" target="_blank" className="mr-6 text-slate-400">
                                    <span className="text-lg fab fa-twitter"></span>
                                </a>
                                <a href="javascript:;" target="_blank" className="mr-6 text-slate-400">
                                    <span className="text-lg fab fa-instagram"></span>
                                </a>
                                <a href="javascript:;" target="_blank" className="mr-6 text-slate-400">
                                    <span className="text-lg fab fa-pinterest"></span>
                                </a>
                                <a href="javascript:;" target="_blank" className="mr-6 text-slate-400">
                                    <span className="text-lg fab fa-github"></span>
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3">
                            <div className="w-8/12 max-w-full px-3 mx-auto mt-1 text-center flex-0">
                                <p className="mb-0 text-slate-400">
                                    Copyright ©<script>document.write(new Date().getFullYear());</script>
                                    Argon Dashboard 2 by Creative Tim.
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </main>
        </div>
    );
};

export default Login;
