import React from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from '../routes';
import Footer from './Footer';
import Sidebar from './Sidebar';
import TopNav from './TopNav';

const Main = () => {
    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    return (
        <>
            <Sidebar
                openDrawer={sidebarOpen}
                toggleSidebar={() => {
                    setSidebarOpen(!sidebarOpen);
                }}
            />

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl overflow-y-auto">
                <TopNav
                    toggleSidebar={() => {
                        setSidebarOpen(!sidebarOpen);
                    }}
                />

                <div className="w-full px-6 py-6 mx-auto">
                    <Routes>
                        {routes.map((route, index) => (
                            <Route key={index} path={route.path} element={<route.component />} />
                        ))}
                    </Routes>

                    <Footer />
                </div>
            </main>
        </>
    );
};

export default Main;
