import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import routes from '../routes';

const Sidebar = ({ openDrawer, toggleSidebar }) => {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <aside
            className={
                'fixed inset-y-0 flex-wrap items-center justify-between block w-full p-0 my-4 overflow-y-auto antialiased transition-transform duration-200 -translate-x-full bg-white border-0 shadow-xl dark:shadow-none dark:bg-slate-850 max-w-64 ease-nav-brand z-990 xl:ml-6 rounded-2xl xl:left-0 xl:translate-x-0' +
                (openDrawer ? ' ml-6 translate-x-0' : '')
            }
        >
            <div className="h-19">
                <i onClick={toggleSidebar} className="absolute top-0 right-0 p-4 opacity-50 cursor-pointer fas fa-times dark:text-white text-slate-400 xl:hidden"></i>
                <a
                    className="block px-8 py-6 m-0 text-sm whitespace-nowrap dark:text-white text-slate-700"
                    href="https://demos.creative-tim.com/argon-dashboard-tailwind/pages/dashboard.html"
                    rel={'noopener noreferrer'}
                    target="_blank"
                >
                    <span className="ml-1 font-semibold transition-all duration-200 ease-nav-brand">StartRides Admin</span>
                </a>
            </div>

            <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />

            <div className="items-center block w-auto max-h-screen overflow-auto h-sidenav grow basis-full">
                <ul className="flex flex-col pl-0 mb-0">
                    {routes
                        .filter((e) => !e?.hidden)
                        .map((route, index) => {
                            return (
                                <li key={index} className="mt-0.5 w-full">
                                    <Link
                                        className={
                                            'py-2.7 dark:text-white dark:opacity-80 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors' +
                                            (location.pathname == route.path ? '  bg-blue-500/13' : '')
                                        }
                                        to={route.path}
                                        onClick={() => {
                                            // console.log(route.path);
                                            // navigate(route.path);
                                            toggleSidebar();
                                        }}
                                    >
                                        {' '}
                                        <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                                            {/* {route.icon} */}
                                            <i className={'relative top-0 text-sm leading-normal ' + route.icon}></i>
                                        </div>
                                        <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{route.name}</span>
                                    </Link>
                                </li>
                            );
                        })}
                </ul>
            </div>
        </aside>
    );
};

export default React.memo(Sidebar);
