import axios from '../../services/api';
import React, { useEffect } from 'react';
import DropdownButton from '../../components/DropdownButton';
import { Button, Dropdown, Popover } from 'antd';

const BookingCard = ({ booking }) => {
    return (
        <div className="flex flex-col w-full max-w-full mb-6 md:w-1/3 lg:w-1/3 my-2 xl:w-1/4 md:mb-0">
            <div className="mx-2  py-3 px-5  rounded-lg bg-slate-300 dark:bg-slate-900 shadow-lg">
                <div className="flex flex-row items-center">
                    <div>
                        <span>#900</span>
                        {
                            // active with green border
                            <span className="px-2 py-1 ml-2 text-xs font-semibold text-green-900 uppercase bg-green-200 rounded-full">Active</span>
                        }
                    </div>
                    <i className="fas fa-ellipsis-v ml-auto"></i>
                </div>
                <div className="flex flex-row items-center my-3">
                    <img src="https://www.w3schools.com/howto/img_avatar.png" alt="vehicle" className="w-20 h-20 rounded-full" />
                    <div className="flex flex-col ml-4">
                        <span className="text-sm font-medium text-gray-700 dark:text-white">John Doe</span>
                        <span className="text-sm font-medium text-gray-500 dark:text-white">+91 9999999999</span>
                        <span className="text-sm font-medium text-gray-700 dark:text-white">Maruti Suzuki Swift</span>
                        <span className="text-sm font-medium text-gray-500 dark:text-white">DL 1C 1234</span>
                    </div>
                </div>
                <hr className="w-full my-3 border-t-2 border-dashed border-gray-300 dark:border-gray-700" />
                <div className="flex flex-row py-1 my-3">
                    <div className="flex flex-col">
                        <span className="text-sm font-semibold text-gray-800 dark:text-white">START</span>
                        <span className="text-sm font-medium text-gray-700 dark:text-white">12/12/2021 10:00 AM</span>
                    </div>
                    <div className="flex flex-col ml-auto text-right">
                        <span className="text-sm font-semibold text-gray-500 dark:text-white">END</span>
                        <span className="text-sm font-medium text-gray-700 dark:text-white">12/12/2021 10:00 AM</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const BookingRow = ({ booking }) => {
    return (
        <tr className="border-b border-gray-200 dark:border-gray-700 dark:bg-slate-900 hover:bg-slate-800 dark:hover:bg-slate-800">
            <td className="px-4 py-3">
                <div className="flex items-center text-sm">
                    <div>
                        <p className="font-semibold text-gray-700 dark:text-white">John Doe</p>
                        <p className="text-xs text-gray-600 dark:text-white">+91 9999999999</p>
                    </div>
                </div>
            </td>
            <td className="px-4 py-3 text-sm">
                <p className="font-semibold text-gray-700 dark:text-white">Maruti Suzuki Swift</p>
                <p className="text-xs text-gray-600 dark:text-white">DL 1C 1234</p>
            </td>
            <td className="px-4 py-3 text-sm">
                <p className="font-semibold text-gray-700 dark:text-white">Start 12/12/2021 10:00 AM</p>
                <p className="text-xs text-gray-600 dark:text-white">End 12/12/2021 10:00 AM</p>
            </td>
            <td className="px-4 py-3 text-sm">
                <span className="px-2 py-1 text-xs font-semibold text-green-900 uppercase bg-green-200 rounded-full">Active</span>
            </td>
            <td className="px-4 py-3 text-sm">
                <div className="flex items-center space-x-4 text-sm">
                    {
                        // dropdown assign button
                        <Popover
                            showArrow={false}
                            overlayInnerStyle={{ padding: 0 }}
                            content={
                                <div className="dark:bg-slate-700" style={{ width: 300, padding: 10 }}>
                                    <div style={{ height: 150 }} className="flex flex-col overflow-hidden ">
                                        {
                                            // bikes list
                                            <div className="flex flex-col overflow-y-auto">
                                                <div className="flex flex-col items-left py-1 my-1 text-sm bg-slate-800 px-2 rounded-md font-medium leading-5 text-white">
                                                    <span className="text-lg">Maruti Suzuki Swift</span>
                                                    <span className="text-sm">DL 1C 1234</span>
                                                </div>
                                                <div className="flex flex-col items-left py-1 my-1 text-sm bg-slate-800 px-2 rounded-md font-medium leading-5 text-white">
                                                    <span className="text-lg">Maruti Suzuki Swift</span>
                                                    <span className="text-sm">DL 1C 1234</span>
                                                </div>
                                                <div className="flex flex-col items-left py-1 my-1 text-sm bg-slate-800 px-2 rounded-md font-medium leading-5 text-white">
                                                    <span className="text-lg">Maruti Suzuki Swift</span>
                                                    <span className="text-sm">DL 1C 1234</span>
                                                </div>
                                                <div className="flex flex-col items-left py-1 my-1 text-sm bg-slate-800 px-2 rounded-md font-medium leading-5 text-white">
                                                    <span className="text-lg">Maruti Suzuki Swift</span>
                                                    <span className="text-sm">DL 1C 1234</span>
                                                </div>
                                                <div className="flex flex-col items-left py-1 my-1 text-sm bg-slate-800 px-2 rounded-md font-medium leading-5 text-white">
                                                    <span className="text-lg">Maruti Suzuki Swift</span>
                                                    <span className="text-sm">DL 1C 1234</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="flex flex-row justify-center mt-3">
                                        <button className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-md active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue">
                                            <span>Assign</span>
                                        </button>
                                    </div>
                                </div>
                            }
                        >
                            <Button className=' dark:bg-gray-800 border-none'>Assign / Re-Assign</Button>
                        </Popover>
                    }
                    <button className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-blue">
                        <span>Reject</span>
                    </button>
                </div>
            </td>
        </tr>
    );
};

const Bookings = () => {
    const [bookings, setBookings] = React.useState([]);

    useEffect(() => {
        axios
            .get('/bookings', {
                params: {
                    status: 'pending',
                    skip: 0,
                    limit: 10,
                },
            })
            .then((res) => {
                console.log(res.data);
                setBookings(res.data.data);
            });
    }, []);

    return (
        <div>
            <div className="flex flex-wrap -mx-3">
                <div className="w-full max-w-full px-3 shrink-0 md:w-12/12 md:flex-0">
                    {
                        // Bookings table
                        <div>
                            <div className="flex flex-col w-full h-full p-6 mb-4 bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-col">
                                        <span className="text-sm font-medium text-gray-500 dark:text-white">Bookings</span>
                                        <span className="text-xl font-semibold text-gray-700 dark:text-white">All Bookings</span>
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="text-sm font-medium text-gray-500 dark:text-white">Total</span>
                                        <span className="text-xl font-semibold text-gray-700 dark:text-white">0</span>
                                    </div>
                                </div>
                                <div className="flex flex-col mt-4">
                                    <div className="flex flex-row justify-between">
                                        <div className="flex flex-col">
                                            <span className="text-sm font-medium text-gray-500 dark:text-white">Active</span>
                                            <span className="text-xl font-semibold text-gray-700 dark:text-white">0</span>
                                        </div>
                                        <div className="flex flex-col">
                                            <span className="text-sm font-medium text-gray-500 dark:text-white">Completed</span>
                                            <span className="text-xl font-semibold text-gray-700 dark:text-white">0</span>
                                        </div>
                                        <div className="flex flex-col">
                                            <span className="text-sm font-medium text-gray-500 dark:text-white">Cancelled</span>
                                            <span className="text-xl font-semibold text-gray-700 dark:text-white">0</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="relative overflow-hidden flex p-3 flex-col min-w-0 mb-6 break-words border-0 border-transparent dark:bg-slate-850 dark:shadow-dark-xl  border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                {/* <div className="flex flex-wrap ">
                                    {bookings.map((booking) => {
                                        return <BookingCard booking={booking} />;
                                    })}
                                </div> */}
                                <div className='overflow-x-auto'>
                                    <table className="w-full whitespace-nowrap  overflow-x-auto">
                                        <thead>
                                            <tr className="text-xs font-semibold tracking-wide text-left text-white dark:bg-slate-900 uppercase border-b dark:border-gray-700 dark:text-gray-400 ">
                                                <th className="px-4 py-3">Booking ID</th>
                                                <th className="px-4 py-3">Customer</th>
                                                <th className="px-4 py-3">Date</th>
                                                <th className="px-4 py-3">Status</th>
                                                <th className="px-4 py-3">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y dark:divide-slate-700 ">
                                            {bookings.map((booking) => {
                                                return <BookingRow booking={booking} />;
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Bookings;
