import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './layout/Main';
import Sidebar from './layout/Sidebar';
import {} from './firebase.config';
import { AuthContext } from './context/AuthContext';
import Login from './views/Auth/Login';
const AppMain = () => {
    return (
        <>
            <Main />
        </>
    );
};

function App() {
    const user = React.useContext(AuthContext);
    return (
        <div className="App dark dark:bg-slate-900">
            <Routes>
                <Route path="/*" element={user.user ? <AppMain /> : <Login />} />
            </Routes>
        </div>
    );
}

export default App;
